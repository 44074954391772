import React from "react";
import { Typography, Image, Divider } from "antd";
import encabezadoDependencias from "../img/sobreNosotros/dependencias.jpg";

const { Title, Paragraph } = Typography;

const Dependencias = () => (
  <div>
    <Image
      src={encabezadoDependencias}
      alt="Encabezado Dependencias"
      width="100%"
    />
    <Divider />
    <Title level={2}>Dependencias</Title>
    <Paragraph>
      Las dependencias municipales son las diferentes áreas y oficinas
      encargadas de llevar a cabo las funciones del gobierno local. Aquí podrás
      encontrar información sobre cada una de ellas y sus responsabilidades.
    </Paragraph>
  </div>
);

export default Dependencias;
