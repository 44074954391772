import React, { useState } from "react";
import { Modal, Button } from "antd";

import "./memoria.css"; // Importar archivo CSS para la animación

const Memoria = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };

  return (
    <div className="memoria-container">
      <div className="button-container" onClick={showModal}>
        <div>
          <p>
            Memoria de labores de <br />
            <span>Concepción Las Minas </span>
            2022-2023
            <br />
            <Button type="text" className="clickme">
              Haz Click aquí
            </Button>
          </p>
        </div>
      </div>

      <Modal
        title="Memoria de Labores 2023"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <iframe
          allowFullScreen="allowfullscreen"
          scrolling="no"
          className="fp-iframe"
          src="https://heyzine.com/flip-book/87dbb81755.html"
          style={{ border: "0px", width: "100%", height: "600px" }}
        ></iframe>
      </Modal>
    </div>
  );
};

export default Memoria;
