import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGraduationCap,
  faRoad,
  faBuilding,
  faShieldAlt,
  faHeartbeat,
  faTree,
  faUsers,
  faHandsHelping,
  faTools,
} from "@fortawesome/free-solid-svg-icons";
import { Tabs, Divider, Card, Timeline } from "antd";
import "./SobreNosotros.css";
import FadeInOnScroll from "./FadeInOnScroll";
import Cimg2 from "./Cimg2";
import CustomCarousel from "./CustomCarousel";
import PoliticasChart from "./charts/politicasChart";
import EducacionChart from "./charts/EducacionChart";
import RedVialChart from "./charts/RedVialChart";
import DesarrolloChart from "./charts/DesarrolloChart";
import ServiciosChart from "./charts/ServiciosChart";
import SeguridadChart from "./charts/SeguridadChart";
import SaludChart from "./charts/SaludChart";
import MedioAmbienteChart from "./charts/MedioAmbienteChart";
const { TabPane } = Tabs;

const SobreNosotrosPage = () => {
  const tabContents = [
    {
      label: (
        <span className="label-icon purple">
          <div>
            <FontAwesomeIcon icon={faGraduationCap} />
          </div>{" "}
          <p>Educación</p>
        </span>
      ),
      content: (
        <div className="politicaMunicipal">
          <Timeline
            items={[
              {
                color: "#8a2be2",
                children: (
                  <span className="timeline-item">
                    <EducacionChart />
                  </span>
                ),
              },
              {
                color: "#8a2be2",
                children: (
                  <span className="timeline-item">
                    <Cimg2
                      imageUrl="/img/politicas/educacion/1.webp"
                      text="Apoyo a la Educación del Ministerio de Educación"
                    />
                  </span>
                ),
              },
              {
                color: "#8a2be2",
                children: (
                  <span className="timeline-item">
                    <Cimg2
                      imageUrl="/img/politicas/educacion/2.webp"
                      text="Apoyo a la Educación Colegio Municipal de Educación
                    Diversificada (CMED)"
                    />
                  </span>
                ),
              },
              {
                color: "#8a2be2",
                children: (
                  <span className="timeline-item">
                    <Cimg2
                      imageUrl="/img/politicas/educacion/3.webp"
                      text="Capacitación a la Educación de Mujeres, Jóvenes, Niños y
                    Adultos Mayores"
                    />
                  </span>
                ),
              },
              {
                color: "#8a2be2",
                children: (
                  <span className="timeline-item">
                    <Cimg2
                      imageUrl="/img/politicas/educacion/4.webp"
                      text="Apoyo a la Educación Escuela Municipal de Deportes"
                    />
                  </span>
                ),
              },
              {
                color: "#8a2be2",
                children: (
                  <span className="timeline-item">
                    <Cimg2
                      imageUrl="/img/politicas/educacion/5.webp"
                      text="Apoyo a la Educación Escuela Municipal de Música y Arte"
                    />
                  </span>
                ),
              },
            ]}
          />
        </div>
      ),
    },
    {
      label: (
        <span className="label-icon darkblue">
          <div>
            <FontAwesomeIcon icon={faRoad} />
          </div>{" "}
          <p>Red Vial</p>
        </span>
      ),
      content: (
        <div className="politicaMunicipal">
          <Timeline
            items={[
              {
                color: "#8a2be2",
                children: (
                  <span className="timeline-item">
                    <RedVialChart />
                  </span>
                ),
              },
              {
                color: "#ffd700",
                children: (
                  <span className="timeline-item">
                    <Cimg2
                      imageUrl="/img/politicas/vial/1.webp"
                      text="Adquisición de Maquinaria para el Municipio"
                    />
                  </span>
                ),
              },
              {
                color: "#ffd700",
                children: (
                  <span className="timeline-item">
                    <Cimg2
                      imageUrl="/img/politicas/vial/2.webp"
                      text="Conservación Carreteras de Terracería"
                    />
                  </span>
                ),
              },
              {
                color: "#ffd700",
                children: (
                  <span className="timeline-item">
                    <Cimg2
                      imageUrl="/img/politicas/vial/3.webp"
                      text="Conservación Carreteras de Terracería"
                    />
                  </span>
                ),
              },
            ]}
          />
        </div>
      ),
    },
    {
      label: (
        <span className="label-icon sienna">
          <div>
            <FontAwesomeIcon icon={faBuilding} />
          </div>{" "}
          <p>
            Desarrollo <br />
            Económico
          </p>
        </span>
      ),
      content: (
        <div className="politicaMunicipal">
          <Timeline
            items={[
              {
                color: "#sienna",
                children: (
                  <span className="timeline-item">
                    <DesarrolloChart />
                  </span>
                ),
              },
              {
                color: "sienna",
                children: (
                  <span className="timeline-item">
                    <Cimg2
                      imageUrl="/img/politicas/desarrollo/1.webp"
                      text="Dotación de insumos y fertilizantas para el año 2023"
                    />
                  </span>
                ),
              },
              {
                color: "sienna",
                children: (
                  <span className="timeline-item">
                    <Cimg2
                      imageUrl="/img/politicas/desarrollo/2.webp"
                      text=" Apoyo Asistencia Técnica a la Cooperativa Integral de
                    Comercialización Concepción Las Minas, (COINCEP)"
                    />
                  </span>
                ),
              },
            ]}
          />
        </div>
      ),
    },
    {
      label: (
        <span className="label-icon orange">
          <div>
            <FontAwesomeIcon icon={faTools} />
          </div>{" "}
          <p>
            Servicios <br /> Públicos
          </p>
        </span>
      ),
      content: (
        <div className="politicaMunicipal">
          <Timeline
            items={[
              {
                color: "#444",
                children: (
                  <span className="timeline-item">
                    <ServiciosChart />
                  </span>
                ),
              },
              {
                color: "#444",
                children: (
                  <span className="timeline-item">
                    <Cimg2
                      imageUrl="/img/politicas/servicios/2.webp"
                      text="Mejoramiento Camino Rural Parada de Buses Aldea La Ermita"
                    />
                  </span>
                ),
              },
              {
                color: "#444",
                children: (
                  <span className="timeline-item">
                    <Cimg2
                      imageUrl="/img/politicas/servicios/3.webp"
                      text="Conservación Sistema de Agua Potable el Área Urbana y Rural "
                    />
                  </span>
                ),
              },
              {
                color: "#444",
                children: (
                  <span className="timeline-item">
                    <Cimg2
                      imageUrl="/img/politicas/servicios/4.webp"
                      text="Construcción Redes y Líneas Eléctricas de Distribución Caserío El Aguajal, Aldea El Socorro"
                    />
                  </span>
                ),
              },
              {
                color: "#444",
                children: (
                  <span className="timeline-item">
                    <Cimg2
                      imageUrl="/img/politicas/servicios/5.webp"
                      text="Mejoramiento Calle y Drenaje Sanitario Cabecera Municipal "
                    />
                  </span>
                ),
              },
              {
                color: "#444",
                children: (
                  <span className="timeline-item">
                    <Cimg2
                      imageUrl="/img/politicas/servicios/6.webp"
                      text="Saneamiento Mercado Municipal del Área Urbana y Rural "
                    />
                  </span>
                ),
              },
              {
                color: "#444",
                children: (
                  <span className="timeline-item">
                    <Cimg2
                      imageUrl="/img/politicas/servicios/7.webp"
                      text="onservación Red de Alumbrado Público del Área Urbana y
                    Rural  "
                    />
                  </span>
                ),
              },
              {
                color: "#444",
                children: (
                  <span className="timeline-item">
                    <Cimg2
                      imageUrl="/img/politicas/servicios/8.webp"
                      text="Conservación Cementerio del Área Urbana y Rural "
                    />
                  </span>
                ),
              },
              {
                color: "#444",
                children: (
                  <span className="timeline-item">
                    <Cimg2
                      imageUrl="/img/politicas/servicios/9.webp"
                      text="Conservación Cementerio del Área Urbana y Rural "
                    />
                  </span>
                ),
              },
            ]}
          />
        </div>
      ),
    },
    {
      label: (
        <span className="label-icon blue">
          <div>
            <FontAwesomeIcon icon={faShieldAlt} />
          </div>{" "}
          <p>Seguridad</p>
        </span>
      ),
      content: (
        <div className="politicaMunicipal">
          <Timeline
            items={[
              {
                children: (
                  <span className="timeline-item">
                    <SeguridadChart />
                  </span>
                ),
              },
              {
                children: (
                  <span className="timeline-item">
                    <Cimg2
                      imageUrl="/img/politicas/seguridad/1.webp"
                      text="Apoyo servicios de ordenamiento vial y seguridad ciudadana "
                    />
                  </span>
                ),
              },
            ]}
          />
        </div>
      ),
    },
    {
      label: (
        <span className="label-icon red">
          <div>
            <FontAwesomeIcon icon={faHeartbeat} />{" "}
          </div>
          <p>Salud</p>
        </span>
      ),
      content: (
        <div className="politicaMunicipal">
          <Timeline
            items={[
              {
                color: "#8a2be2",
                children: (
                  <span className="timeline-item">
                    <SaludChart />
                  </span>
                ),
              },
              {
                color: "#ff4500",
                children: (
                  <span className="timeline-item">
                    <Cimg2
                      imageUrl="/img/politicas/salud/1.webp"
                      text="Apoyo a la salud en el municipio de Concepción Las Minas,
                    Chiquimula "
                    />
                  </span>
                ),
              },
            ]}
          />
        </div>
      ),
    },
    {
      label: (
        <span className="label-icon green">
          <div>
            <FontAwesomeIcon icon={faTree} />
          </div>{" "}
          <p>
            Medio<br></br> Ambiente
          </p>
        </span>
      ),
      content: (
        <div className="politicaMunicipal">
          <Timeline
            items={[
              {
                children: (
                  <span className="timeline-item">
                    <MedioAmbienteChart />
                  </span>
                ),
              },
              {
                color: "#32cd32",
                children: (
                  <span className="timeline-item">
                    <Cimg2
                      imageUrl="/img/politicas/ambiente/1.webp"
                      text=" Apoyo a la reforestación del medio ambiente en el área
                    urbana y rural "
                    />
                  </span>
                ),
              },
              {
                color: "#32cd32",
                children: (
                  <span className="timeline-item">
                    <Cimg2
                      imageUrl="/img/politicas/ambiente/2.webp"
                      text="Forestación de huertos mixtos, viveros frutales y especies
                    menores en el área urbana y cabecera municipal "
                    />
                  </span>
                ),
              },
              {
                color: "#32cd32",
                children: (
                  <span className="timeline-item">
                    <Cimg2
                      imageUrl="/img/politicas/ambiente/3.webp"
                      text="Saneamiento y urbanización para la recolección de desechos
                    sólidos del área urbana y rural "
                    />
                  </span>
                ),
              },
            ]}
          />
        </div>
      ),
    },
  ];

  const secciones = [
    {
      icono: faHandsHelping,
      titulo: "Chucteros Orgullosos",
      contenido:
        "Estamos orgullosos de servir a este bello municipio lleno de gente humilde, amable y trabajadora.",
    },
    {
      icono: faUsers,
      titulo: "Trabajadores con Experiencia",
      contenido:
        "Contamos con profesionales altamente capacitados que trabajan día tras día para poder brindarte la mejor atención y el mejor servicio.",
    },
    {
      icono: faTools,
      titulo: "Servicios para Toda la Población",
      contenido:
        "Todos tenemos derecho a llevar una vida digna, por eso trabajamos como Municipalidad para garantizar ese derecho de nacimiento y brindar los mejores servicios que tenemos a tu disposición.",
    },
  ];

  return (
    <div className="containerSN">
      <FadeInOnScroll offset={100}>
        <h1>Sobre Nosotros</h1>
        <h2>Estamos al servicio de nuestro Municipio</h2>
        <p>
          Servir a nuestro municipio es una responsabilidad que llevamos con
          orgullo y dedicación. Estamos comprometidos a mejorar la vida de
          nuestra población y hacer de Concepción Las Minas un hogar aún más
          maravilloso para todos. <br />
          <br />
          ¡Juntos construyendo un futuro brillante para nuestro Municipio!
        </p>
        <Divider />
        <Card bordered size="large">
          <p className="politicasP">
            <PoliticasChart />
            <br></br>
            Las <b>Políticas Públicas</b> a nivel municipal, son las{" "}
            <b>Acciones</b> del Gobierno Local, que tienen como propósito
            alcanzar <b>objetivos de interés público</b>, que permiten la
            solución de <b>conflictos y problemáticas</b> locales además de ser
            elementos que mejoran la <b>transparencia</b> de la gestión pública
            Municipal.
          </p>
        </Card>
        <Divider />
      </FadeInOnScroll>

      <FadeInOnScroll offset={200}>
        <Divider />
        <Divider style={{ borderColor: "#06c", paddingTop: "1.5em" }}>
          ¡Haz Click en una política para más inforamación!
        </Divider>
        <div className="tabs-container">
          <Tabs className="centered-tabs">
            {tabContents.map((item, index) => (
              <TabPane tab={item.label} key={index}>
                {item.content}
              </TabPane>
            ))}
          </Tabs>
        </div>
        <Divider />
      </FadeInOnScroll>

      <Divider />
      <FadeInOnScroll offset={200}>
        <div
          style={{
            maxWidth: "800px",
            margin: "auto",
            padding: "16px",
          }}
        >
          <div style={{ position: "relative", paddingTop: "56.25%" }}>
            <iframe
              title="Embedded Video"
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/G75WXLDlkyM?si=BaxBcORg87wqG8us"
              frameBorder="0"
              allowFullScreen
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                borderRadius: "1em",
              }}
            />
          </div>
        </div>
      </FadeInOnScroll>

      <Divider />
      <FadeInOnScroll offset={200}>
        <h2>¡ES UN HONOR SERVIRTE!</h2>
        <p>
          Nuestro objetivo principal es el <b>avance</b> de Concepción Las
          Minas, queremos expresar nuestro más sincero agradecimiento por
          permitirnos <b>servirte.</b> <br />
        </p>
      </FadeInOnScroll>

      <Divider />
      <FadeInOnScroll offset={200}>
        <div>
          {secciones.map((seccion, index) => (
            <div key={index} className="d-flex align-items-center">
              <div className="me-4 d-flex align-items-center justify-content-center">
                <FontAwesomeIcon icon={seccion.icono} size="1x" />
              </div>
              <div>
                <h1>{seccion.titulo}</h1>
                <p>{seccion.contenido}</p>
              </div>
            </div>
          ))}
        </div>
        <Divider />
      </FadeInOnScroll>
    </div>
  );
};

export default SobreNosotrosPage;
