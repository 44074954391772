import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import "./politicasChart.css"; // Importa la hoja de estilos

const PoliticasChart = () => {
  const [fontSize, setFontSize] = useState(14); // Tamaño de fuente predeterminado

  // Función para ajustar el tamaño de la fuente según el tamaño de la pantalla
  const adjustFontSize = () => {
    if (window.innerWidth < 768) {
      setFontSize(7); // Tamaño de fuente para móviles
    } else {
      setFontSize(16); // Tamaño de fuente para pantallas más grandes
    }
  };

  // Ejecuta el ajuste del tamaño de fuente cuando se carga el componente y al cambiar el tamaño de la ventana
  useEffect(() => {
    adjustFontSize();
    window.addEventListener("resize", adjustFontSize);
    return () => window.removeEventListener("resize", adjustFontSize);
  }, []);

  const data = [
    {
      nombre: ["Red", "Vial"], // Etiqueta en dos líneas usando array
      presupuestado: 9360300.0,
      ejecutado: 6157519.5,
    },
    {
      nombre: ["Servicios", "Públicos"], // Etiqueta en dos líneas usando array
      presupuestado: 1551664.0,
      ejecutado: 1264259.6,
    },
    {
      nombre: ["Medio", "Ambiente"], // Etiqueta en dos líneas usando array
      presupuestado: 2330200.0,
      ejecutado: 2105024.9,
    },
    {
      nombre: ["Desarrollo", "Económico", " Local"], // Etiqueta en dos líneas usando array
      presupuestado: 919300.0,
      ejecutado: 765802.56,
    },
    {
      nombre: ["Seguridad"],
      presupuestado: 946000.0,
      ejecutado: 660754.48,
    },
    { nombre: ["Salud"], presupuestado: 84500.0, ejecutado: 75541.84 },
  ];

  const sortedData = data.sort((a, b) => b.ejecutado - a.ejecutado);

  const formatNumber = (num) => {
    return num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const chartData = {
    labels: sortedData.map((item) => item.nombre),
    datasets: [
      {
        label: "Presupuestado",
        data: sortedData.map((item) => item.presupuestado),
        backgroundColor: "#06cc",
      },
      {
        label: "Ejecutado",
        data: sortedData.map((item) => item.ejecutado),
        backgroundColor: "#0cfc",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          font: {
            size: fontSize, // Usamos el tamaño de fuente dinámico
          },
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return `Q${formatNumber(value)}`;
          },
          font: {
            size: fontSize, // Usamos el tamaño de fuente dinámico
          },
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          font: {
            size: fontSize, // Usamos el tamaño de fuente dinámico
          },
        },
      },
    },
  };

  return (
    <div className="container-chart">
      <h2 className="title-chart">Políticas Públicas</h2>
      <p className="subtitle-chart">hasta 2do cuatrimestre de 2024</p>
      <div className="header-chart">
        <div>
          <p>
            <strong>Total Presupuestado:</strong> Q {formatNumber(17920300.0)}
            <br />
            <strong>Total Ejecutado:</strong> Q {formatNumber(13058001.7)}
          </p>
        </div>
      </div>
      <div className="bar-chart">
        <Bar data={chartData} options={options} />
      </div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th> </th>
              {sortedData.map((item, index) => (
                <th key={index}>{item.nombre.join(" ")}</th> // Mostrar en una línea en la tabla
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="left-align">
                <strong>Presupuesto (Q)</strong>
              </td>
              {sortedData.map((item, index) => (
                <td key={index}>{formatNumber(item.presupuestado)}</td>
              ))}
            </tr>
            <tr>
              <td className="left-align">
                <strong>Ejecutado (Q)</strong>
              </td>
              {sortedData.map((item, index) => (
                <td key={index}>{formatNumber(item.ejecutado)}</td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PoliticasChart;
