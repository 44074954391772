import React from "react";
import { Typography, Image, Divider } from "antd";
import encabezadoOrganigrama from "../img/sobreNosotros/organigrama.jpg";

const { Title, Paragraph } = Typography;

const Organigrama = () => (
  <div>
    <Image
      src={encabezadoOrganigrama}
      alt="Encabezado Organigrama"
      width="100%"
    />
    <Divider />
    <Title level={2}>Organigrama</Title>
    <Paragraph>
      Aquí va la información sobre el organigrama de la municipalidad, con una
      descripción de cada departamento.
    </Paragraph>
  </div>
);

export default Organigrama;
