import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { Tooltip, Divider } from "antd";
import "./politicasChart.css"; // Importa la hoja de estilos

const SeguridadChart = () => {
  const [fontSize, setFontSize] = useState(14); // Tamaño de fuente predeterminado

  // Función para ajustar el tamaño de la fuente según el tamaño de la pantalla
  const adjustFontSize = () => {
    if (window.innerWidth < 768) {
      setFontSize(7); // Tamaño de fuente para móviles
    } else {
      setFontSize(16); // Tamaño de fuente para pantallas más grandes
    }
  };

  // Ejecuta el ajuste del tamaño de fuente cuando se carga el componente y al cambiar el tamaño de la ventana
  useEffect(() => {
    adjustFontSize();
    window.addEventListener("resize", adjustFontSize);
    return () => window.removeEventListener("resize", adjustFontSize);
  }, []);

  const data = [
    {
      nombreCompleto:
        "Apoyo servicios de ordenamiento vial y seguridad ciudadana para el año 2024 en el municipio de Concepción Las Minas, Chiquimula",
      abreviatura: "Ordenamiento vial y seguridad ciudadana",
      presupuestado: 946000.0,
      ejecutado: 660754.48,
    },
  ];

  // No es necesario ordenar ya que solo hay un dato, pero mantenemos la estructura
  const sortedData = data;

  const formatNumber = (num) => {
    return num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const chartData = {
    labels: sortedData.map((item) => item.abreviatura),
    datasets: [
      {
        label: "Presupuestado",
        data: sortedData.map((item) => item.presupuestado),
        backgroundColor: "#06cc",
      },
      {
        label: "Ejecutado",
        data: sortedData.map((item) => item.ejecutado),
        backgroundColor: "#0cfc",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          font: {
            size: fontSize, // Usamos el tamaño de fuente dinámico
          },
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return `Q${formatNumber(value)}`;
          },
          font: {
            size: fontSize, // Usamos el tamaño de fuente dinámico
          },
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          font: {
            size: fontSize, // Usamos el tamaño de fuente dinámico
          },
        },
      },
    },
  };

  return (
    <div className="container-chart">
      <h2 className="title-chart">Seguridad</h2>
      <p className="subtitle-chart">hasta 2do cuatrimestre de 2024</p>
      <div className="header-chart">
        <div>
          <p>
            <strong>Total Presupuestado:</strong> Q {formatNumber(946000.0)}
            <br />
            <strong>Total Ejecutado:</strong> Q {formatNumber(660754.48)}
          </p>
        </div>
      </div>
      <div className="bar-chart">
        <Bar data={chartData} options={options} />
      </div>

      <Divider style={{ borderColor: "#06c", paddingTop: "1.5em" }}>
        ¡Haz Click en cada descripción para ver más!
      </Divider>

      <Divider />
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th> </th>
              {sortedData.map((item, index) => (
                <Tooltip
                  title={item.nombreCompleto}
                  overlayInnerStyle={{ fontSize: "1.75em" }}
                >
                  <th key={index}>{item.abreviatura}</th>
                </Tooltip>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="left-align">
                <strong>Presupuesto (Q)</strong>
              </td>
              {sortedData.map((item, index) => (
                <td key={index}>{formatNumber(item.presupuestado)}</td>
              ))}
            </tr>
            <tr>
              <td className="left-align">
                <strong>Ejecutado (Q)</strong>
              </td>
              {sortedData.map((item, index) => (
                <td key={index}>{formatNumber(item.ejecutado)}</td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SeguridadChart;
