import React from "react";
import { Typography, Divider } from "antd";
import encabezadoAlcaldia from "../img/sobreNosotros/alcaldia.jpg";

const { Title, Paragraph } = Typography;

const Alcaldia = () => (
  <div>
    <style>
      {`
        .header-alcaldia {
          background-image: url(${encabezadoAlcaldia});
          background-size: cover;
          background-position: center;
          height: 700px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding:1.5em!important;
          border-radius: 1em 1em 0 0;
        }

        .header-content {
          text-align: center;
          color: white;
          text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        }

        .header-title {
          font-size: 2.5em;
          margin: 0;
          color: #FFF!important;
        }

        .content {
          padding: 20px;
        }

        .content-text {
          font-size: 1.2em;
          color: #333; /* Asegúrate de que el color del texto sea visible */
        }
      `}
    </style>
    <div className="header-alcaldia">
      <div className="header-content">
        <Title level={2} className="header-title">
          Alcaldía
        </Title>
      </div>
    </div>
    <Divider />
    <div className="content">
      <Paragraph className="content-text">
        Aquí va la información detallada sobre la Alcaldía, sus funciones, el
        alcalde y otros datos relevantes.
      </Paragraph>
    </div>
  </div>
);

export default Alcaldia;
