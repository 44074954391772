import React from "react";
import { Typography, Image, Divider } from "antd";
import encabezadoMisionVision from "../img/sobreNosotros/misionvision.jpg";

const { Title, Paragraph } = Typography;

const MisionVision = () => (
  <div>
    <Image
      src={encabezadoMisionVision}
      alt="Encabezado Misión y Visión"
      width="100%"
    />
    <Divider />
    <Title level={2}>Misión y Visión</Title>
    <Paragraph>
      Nuestra misión es proporcionar servicios públicos de calidad, promoviendo
      el desarrollo sostenible de Concepción Las Minas. Nuestra visión es ser un
      municipio ejemplar, donde el bienestar de nuestros ciudadanos sea nuestra
      prioridad.
    </Paragraph>
  </div>
);

export default MisionVision;
